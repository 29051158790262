import React from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ErrorPage from "./Components/ErrorPage/ErrorPage";
import SnackBarComponent from "./Components/SnackBarComponent/SnackBarComponent";
import withAuthRedirect from "./withAuthRedirect";
import PageWrapper from "./Components/PageWrapper/PageWrapper";
import { UserProvider } from "./context/UserContext";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./ApplicationInsightsService";

const App = () => {
  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ThemeProvider theme={theme}>
          <AuthenticatedTemplate>
            <UserProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/*" element={<PageWrapper />} />
                  <Route path="/error" element={<ErrorPage />} />
                  <Route path="*" element={<Navigate to="/error" />} />
                </Routes>
                <SnackBarComponent />
              </BrowserRouter>
            </UserProvider>
          </AuthenticatedTemplate>
        </ThemeProvider>
      </AppInsightsContext.Provider>
    </>
  );
};

export default withAuthRedirect(App);
