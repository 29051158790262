import React, { createContext, useState, useEffect, useRef } from 'react';
import useAxios from '../CustomHooks/useAxios';
import { appInsights } from "../ApplicationInsightsService";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [callAPI] = useAxios();
  const fetchCalled = useRef(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (fetchCalled.current) return; // Prevent multiple calls
      fetchCalled.current = true;

      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const data = await callAPI(`${apiUrl}/users/me`, "GET");
        setUser(data);

        appInsights.addTelemetryInitializer((env) => {
            env.data = env.data || {};
            env.data["ms-appName"] = "Explorer UI";
            env.data["ms-user"] = data.email;
            env.data["ms-userid"] = data.idpObjectId;
            env.data["ms-customer"] = data.customerName;
        });

      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};