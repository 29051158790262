import React, { useState } from 'react';
import { AppBar, Toolbar, Menu, MenuItem } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/aidon_explorer_white.png';
import { signOutClickHandler } from '../../services/AuthService';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Header() {
  const { accounts, instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    signOutClickHandler(instance, accounts[0]);
    navigate('/');
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <img src={logo} alt='Aidon Explorer' style={{ height: 30, marginTop: 5 }} />
        </div>
        <div>
          <span onClick={handleMenuOpen} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
            {accounts[0]?.idTokenClaims.name}
            {anchorEl ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </span>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { handleLogout(); handleMenuClose(); }}>
              <LogoutIcon />&nbsp;&nbsp;Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;