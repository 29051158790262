import React, { useState, useContext } from "react";
import {
  NavLink,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import UserManagement from "../Admin/UserManagement";
import CustomerManagement from "../Admin/CustomerManagement";
import Reports from "../Report/Report";
import { UserContext } from "../../context/UserContext";
import {
  Drawer,
  List,
  Divider,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import MenuIcon from "@mui/icons-material/Menu";
import AssessmentIcon from "@mui/icons-material/Assessment";
import HomeIcon from '@mui/icons-material/Home';
import CircularProgress from "@mui/material/CircularProgress";
import Home from "../Home/Home";
import CloseIcon from '@mui/icons-material/Close';
// Import Header component
import Header from "../Header/Header";

const drawerWidth = 240;

const PageWrapper = () => {
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const { user, loading } = useContext(UserContext);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <Drawer
        variant="permanent"
        open={open}
        style={{ width: open ? drawerWidth : 60, flexShrink: 0, boxSizing: 'border-box' }}
        PaperProps={{ className: 'sidebar', style: { width: open ? drawerWidth : 60 } }}
      >
        <Toolbar style={{ padding: 8, justifyContent: open ? "flex-end" : "flex-start" }}>
          <IconButton onClick={handleDrawerToggle}>
            {open ? (
              <>
                <CloseIcon />
                {/* <small style={{ marginLeft: 4, color: '#ffffff', fontSize: 14 }}>Close</small> */}
              </>
            ) : (
              <MenuIcon />
            )}
          </IconButton>
        </Toolbar>
        <div>
          <List>
            <ListItem
              button
              component={NavLink}
              to="/"
              selected={location.pathname === "/"}
            >
              <ListItemIcon style={{ marginTop: 4, marginBottom: 4 }}>
                <HomeIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Home" />}
            </ListItem>
            {open && (
              <ListSubheader component="div" id="nested-list-subheader">
                Reports
              </ListSubheader>
            )}
            {user?.reports.map((report) => (
              <ListItem
                button
                component={NavLink}
                to={`/reports/${report.id}`}
                selected={location.pathname === `/reports/${report.id}`}
                key={report.id}
              >
                <ListItemIcon style={{ marginTop: 4, marginBottom: 4 }}> 
                  <AssessmentIcon />
                </ListItemIcon>
                {open && <ListItemText primary={report.reportName} />}
              </ListItem>
            ))}
            {user?.explorerRoles.includes("Admin") && (
              <>
                <Divider />
                {open && (
                  <ListSubheader component="div" id="nested-list-subheader">
                    Admin Management
                  </ListSubheader>
                )}
                <ListItem
                  button
                  component={NavLink}
                  to="/admin/manage-users"
                  selected={location.pathname === "/admin/manage-users"}
                >
                  <ListItemIcon style={{ marginTop: 4, marginBottom: 4 }}>
                    <PeopleIcon />
                  </ListItemIcon>
                  {open && <ListItemText primary="Users" />}
                </ListItem>
                <ListItem
                  button
                  component={NavLink}
                  to="/admin/manage-customers"
                  selected={location.pathname === "/admin/manage-customers"}
                >
                  <ListItemIcon style={{ marginTop: 4, marginBottom: 4 }}>
                    <CorporateFareIcon />
                  </ListItemIcon>
                  {open && <ListItemText primary="Customers" />}
                </ListItem>
              </>
            )}
          </List>
        </div>
      </Drawer>
      <main style={{ flexGrow: 1 }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="reports/:reportId" element={<Reports />} />
          <Route path="admin/manage-users" element={<UserManagement />} />
          <Route
            path="admin/manage-customers"
            element={<CustomerManagement />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
    </div>
  );
};

export default PageWrapper;
