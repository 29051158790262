import { useContext } from 'react';
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { GlobalContext } from '../context/Provider';

function useAxios() {
    const { instance, accounts } = useMsal();
    const { setOpenSnackBar } = useContext(GlobalContext);
    const controller = new AbortController();

    const callAPIWithToken = async(dataURL, method, postBody, params, token) => {
        try {
            const req = await axios({
                method: method,
                url: `${dataURL}`,
                data: postBody || null,
                params: params,
                headers: { Authorization: `Bearer ${token}` },
                signal: controller.signal,
                // onDownloadProgress: (progressEvent) => {
                //     let { loaded, total } = progressEvent;
                //     let percentCompleted = Math.floor((loaded * 100) / total);
                //     setLoadingPercentage(percentCompleted);
                // }
            });
            if (req) {
                // console.log(req.data);
                return req.data;
            }
        } catch (error) {
            console.log(error);
            setOpenSnackBar({
                open: true,
                message: error.response.data,
                severity: "error",
              });
            throw error;
        }
    }

    const callAPI = async (dataURL, method, postBody, params) => {
        try {
            await instance.handleRedirectPromise();
            const request = {
                scopes: [process.env["REACT_APP_AADB2C_API_SCOPE"]],
                account: accounts[0]
            };
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            let token = await instance.acquireTokenSilent(request);

            
            return callAPIWithToken(dataURL, method, postBody, params,token.accessToken)
        } catch (error) {
            await instance.handleRedirectPromise();
            const request = {
                scopes: [process.env["REACT_APP_AADB2C_API_SCOPE"]],
                account: accounts[0]
            };

            let token = await instance.acquireTokenRedirect(request);

            return callAPIWithToken(dataURL, method, postBody, params,token.accessToken)
        }
        
    }

    return [callAPI]
}
export default useAxios;