import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import useAxios from "../../CustomHooks/useAxios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { GlobalContext } from "../../context/Provider";
import SnackBarComponent from "../SnackBarComponent/SnackBarComponent";

function Reports() {
  const { reportId } = useParams();
  const [callAPI] = useAxios();
  const [embedConfig, setEmbedConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(
    "Fetching report details..."
  );
  const { setOpenSnackBar } = useContext(GlobalContext);
  const [mounted, setMounted] = useState(true);

  const fetchReport = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      
      if (mounted) {
        const embedInfo = await callAPI(
          `${apiUrl}/reports/${reportId}/embedtoken`,
          "GET"
        );
        setLoadingStatus("Loading report UI...");
        setEmbedConfig({
          type: "report",
          id: embedInfo.reportId,
          embedUrl: embedInfo.embedUrl,
          accessToken: embedInfo.embedToken,
          tokenType: models.TokenType.Embed,
          settings: {
            navContentPaneEnabled: false,
          },
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [reportId, callAPI, mounted]);

  useEffect(() => {
    fetchReport();
    return () => setMounted(false);
  }, [fetchReport, mounted]);

  const resetAndRefetch = () => {
    setEmbedConfig(null);
    setLoading(true);
    setLoadingStatus("Fetching report details...");
    setMounted(false);
    setTimeout(() => {
      setMounted(true);
    }, 100);
  };

  const eventHandlersMap = new Map([
    [
      "loaded",
      function () {
        setLoadingStatus("Rendering the report...");

        console.log("Report has loaded", window.report);
      },
    ],
    [
      "rendered",
      function () {
        console.log("Report has rendered", window.report);
        setLoading(false);
      },
    ],
    [
      "error",
      function (event) {
        if (event) {
          if (event.detail.message === "CapacityNotActive" || event.detail.message === "TokenExpired") {
            setOpenSnackBar({
              open: true,
              message: "Could not load report. Refreshing the report... This may take a minute.",
              severity: "error",
            });
            resetAndRefetch();
          } else {
            console.error(event.detail);
          }
        }
      },
    ],
  ]);

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "calc(100vh - 70px)",
        }}
      >
        {embedConfig && (
          <PowerBIEmbed
            embedConfig={embedConfig}
            cssClassName={"report"}
            eventHandlers={eventHandlersMap}
            getEmbeddedComponent={(embeddedReport) => {
              //console.log("Embedded Report:", embeddedReport);
            }}
          />
        )}
        {loading && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bgcolor="rgba(255, 255, 255, 1)"
          >
            <CircularProgress />
            <p>{loadingStatus}</p>
          </Box>
        )}
      </div>
      <SnackBarComponent />
    </>
  );
}

export default Reports;
