import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

const withAuthRedirect = (WrappedComponent) => {
  return (props) => {
    const { instance, accounts, inProgress } = useMsal();

    const ensureLoggedIn = async () => {
      if (!inProgress && accounts.length === 0) {
        try {
          await instance.loginRedirect(loginRequest);
        } catch (error) {
          console.error(error);
        }
      }
    };

    useEffect(() => {
      instance.handleRedirectPromise()
      .then(res=>{
        ensureLoggedIn();
      })
      .catch(err => {
        console.error(err);
      });
    }, []);

    return accounts.length > 0 ? <WrappedComponent {...props} /> : null;
  };
};

export default withAuthRedirect;